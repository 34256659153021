import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, parseISO, sub } from 'date-fns';
import Airport from './entities/Airport';
import Country from './entities/Country';
import CountryGroup from './entities/CountryGroup';
import Region from './entities/Region';
import Subregion from './entities/Subregion';
import { Template } from './entities/Template';
import ExtractColumns from './enums/extractColumns.enum';
import { FilterLevels } from './enums/filterLevels.enum';
import PeriodType from './enums/periodType.enum';
import TimeFetchingType from './enums/timeFetchingType.enum';

import { metricTypes } from './metricTypes';

interface State {
  currentTemplate: Template & { modified: boolean };

  currentStep: number;
  filters: {
    airports: Airport[];
    countries: Country[];
    countryGroups: CountryGroup[];
    regions: Region[];
    subregions: Subregion[];
  };
  downloadAccessDenied: boolean;
}

const fromYear = format(sub(new Date(), { years: 2 }), 'yyyy-MM');

export const initialState: State = {
  currentTemplate: {
    name: '',
    nbOfLastMonths: 2,
    startYear: fromYear,
    endYear: format(new Date(new Date().setMonth(new Date().getMonth() - 1)), 'yyyy-MM'),
    periodType: PeriodType.Monthly,
    countries: [],
    regions: [],
    airports: [],
    countryGroups: [],
    metrics: metricTypes.flatMap((c) => c.childs).map((x) => x.value),
    columnBy: ExtractColumns.Metrics,
    timeFetchingType: TimeFetchingType.Latest,
    modified: false,
    aggregationLevel: FilterLevels.World,
  },
  currentStep: 0,
  filters: {
    airports: [],
    countries: [],
    countryGroups: [],
    regions: [],
    subregions: [],
  },
  downloadAccessDenied: false,
};

// Actions
const analystSlice = createSlice({
  name: 'analyst',
  initialState,
  reducers: {
    newReport: (state, action: PayloadAction<boolean>) => {
      const isFreeTrial = action.payload;
      if (isFreeTrial) {
        return {
          ...initialState,
          currentTemplate: {
            ...initialState.currentTemplate,
            timeFetchingType: TimeFetchingType.From,
            startYear: format(new Date(2021, 0, 1), 'yyyy-MM'),
            endYear: format(new Date(2021, 11, 31), 'yyyy-MM'),
          },
        };
      }
      return { ...initialState };
    },
    changeActiveStep: (state, action: PayloadAction<number>) => ({
      ...state,
      currentStep: action.payload,
    }),
    changeDatasetType: (state, action: PayloadAction<PeriodType>) => {
      let formattedEndYear = state.currentTemplate.endYear;
      let formattedStartYear = state.currentTemplate.startYear;
      let { nbOfLastMonths } = state.currentTemplate;

      if (state.currentTemplate.periodType === PeriodType.Monthly && action.payload === PeriodType.Annually) {
        formattedEndYear = format(parseISO(state.currentTemplate.endYear), 'yyyy');
        formattedStartYear = format(parseISO(state.currentTemplate.startYear), 'yyyy');
        nbOfLastMonths *= 12;
      }
      if (state.currentTemplate.periodType === PeriodType.Annually && action.payload === PeriodType.Monthly) {
        formattedEndYear = format(parseISO(state.currentTemplate.endYear), 'yyyy-MM');
        formattedStartYear = format(parseISO(state.currentTemplate.startYear), 'yyyy-MM');
        nbOfLastMonths = Math.ceil(nbOfLastMonths / 12);
      }

      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          periodType: action.payload,
          startYear: formattedStartYear,
          endYear: formattedEndYear,
          nbOfLastMonths,
          modified: true,
        },
      };
    },
    changeTimeType: (state, action: PayloadAction<TimeFetchingType>) => ({
      ...state,
      currentTemplate: {
        ...state.currentTemplate,
        timeFetchingType: action.payload,
        modified: true,
      },
    }),
    changeFromYear: (state, action: PayloadAction<string>) => ({
      ...state,
      currentTemplate: {
        ...state.currentTemplate,
        startYear: action.payload,
        modified: true,
      },
    }),
    changeToYear: (state, action: PayloadAction<string>) => ({
      ...state,
      currentTemplate: {
        ...state.currentTemplate,
        endYear: action.payload,
        modified: true,
      },
    }),
    changeLastYear: (state, action: PayloadAction<number>) => {
      let nbOfLastMonths = action.payload;
      if (state.currentTemplate.periodType === PeriodType.Annually) {
        nbOfLastMonths *= 12;
      }

      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          nbOfLastMonths,
          modified: true,
        },
      };
    },
    changeAggregationLevel: (state, action: PayloadAction<FilterLevels>) => ({
      ...state,
      currentTemplate: {
        ...state.currentTemplate,
        aggregationLevel: action.payload,
      },
    }),
    setSelectedAggregations: (state, action: PayloadAction<{ key: FilterLevels; value: string[] }>) => {
      let accessKey = '';
      if (action.payload.key === FilterLevels.Airport) {
        accessKey = 'airports';
      }
      if (action.payload.key === FilterLevels.Country) {
        accessKey = 'countries';
      }
      if (action.payload.key === FilterLevels.CountryGroup) {
        accessKey = 'countryGroups';
      }
      if (action.payload.key === FilterLevels.Region) {
        accessKey = 'regions';
      }
      if (action.payload.key === FilterLevels.Subregion) {
        accessKey = 'regions';
      }
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          [accessKey]: action.payload.value,
          modified: true,
        },
      };
    },
    changeMetrics: (state, action: PayloadAction<string[]>) => ({
      ...state,
      currentTemplate: {
        ...state.currentTemplate,
        metrics: action.payload,
        modified: true,
      },
    }),
    changeExportColumn: (state, action: PayloadAction<ExtractColumns>) => ({
      ...state,
      currentTemplate: {
        ...state.currentTemplate,
        columnBy: action.payload,
        modified: true,
      },
    }),
    changeReportTitle: (state, action: PayloadAction<string>) => ({
      ...state,
      currentTemplate: {
        ...state.currentTemplate,
        name: action.payload,
        modified: true,
      },
    }),
    setCurrentTemplate: (state, action: PayloadAction<Template>) => ({
      ...state,
      currentTemplate: {
        ...action.payload,
        modified: false,
      },
    }),
    changeDownloadAccessDenied: (state, action: PayloadAction<boolean>) => ({
      ...state,
      downloadAccessDenied: action.payload,
    }),
  },
});

export const {
  newReport,
  changeActiveStep,
  changeDatasetType,
  changeTimeType,
  changeToYear,
  changeFromYear,
  setSelectedAggregations,
  changeLastYear,
  changeMetrics,
  changeReportTitle,
  changeExportColumn,
  setCurrentTemplate,
  changeDownloadAccessDenied,
} = analystSlice.actions;

export default analystSlice.reducer;
